<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <span class="material-symbols-outlined">inventory_2</span>
          </div>
          <h4 class="title">{{ $t("wms.inventory") }}</h4>
          <!-- <md-button class="md-icon-button md-success add-button" @click="name = null">
            <md-icon>add</md-icon>
          </md-button> -->
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("wms.keywords") }}</label>
                <md-input v-model="filters.keywords" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <md-field :md-clearable="!isWarehouseAssigned">
                <label>{{ $t("wms.warehouse") }}</label>
                <md-select
                  v-model="filters.warehouseId"
                  name="warehouseId"
                  id="warehouseId"
                  :disabled="isWarehouseAssigned"
                  @md-selected="debounceApplyFilters"
                >
                  <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-lay-out-item md-size-25">
              <md-button @click="exportInventories" class="md-success">{{ $t("wms.export") }}</md-button>
            </div>
          </div>
          <md-table v-model="tableData" table-header-color="green" class="table-sticky">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell :md-label="$t('wms.actions')">
                <span v-if="hasPermission('Read', 'Inventories', '')" @click="showHistoryModal(item.sku)"
                  ><md-icon>history</md-icon></span
                >
              </md-table-cell>
              <md-table-cell v-if="hasPermission('LimitRead', 'Inventories', 'Sku')" :md-label="$t('wms.sku')">{{
                item.sku
              }}</md-table-cell>
              <md-table-cell v-if="hasPermission('LimitRead', 'Inventories', 'Name')" :md-label="$t('wms.name')">{{
                item.name
              }}</md-table-cell>
              <md-table-cell v-if="hasPermission('LimitRead', 'Inventories', 'Total')" :md-label="$t('wms.total')"
                ><b>{{ calcTotal(item) }}</b></md-table-cell
              >
              <md-table-cell :key="i.id" v-for="i in warehouseOptions" :value="i.id" :md-label="i.name">{{
                showInventory(item, i.code)
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>

    <modal v-if="historyModal" @close="hideHistoryModal" class-name="historical-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("wms.history") }}: {{ sku }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideHistoryModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div style="height: calc(100vh - 150px); overflow-x: auto">
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div class="">
              <p class="date-title">{{ $t("wms.historyFromDateTime") }}</p>
              <date-time-picker
                :startDate="historyFilters.historyFromDateTime"
                :singleDate="true"
                @onChange="(d) => dateFilterChanged(d, 'historyFromDateTime')"
                class="datetime-input"
              />
            </div>
            <div style="margin-left: 20px">
              <p class="date-title">{{ $t("wms.historyToDateTime") }}</p>
              <date-time-picker
                :startDate="historyFilters.historyToDateTime"
                :singleDate="true"
                @onChange="(d) => dateFilterChanged(d, 'historyToDateTime')"
                class="datetime-input"
              />
            </div>
          </div>
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div style="">
              <md-field md-clearable>
                <label>{{ $t("wms.location") }}</label>
                <md-input v-model="historyFilters.location" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.changeType") }}</label>
                <md-select
                  v-model="historyFilters.changeType"
                  name="filters.changeType"
                  id="filters.changeType"
                  @md-selected="debounceApplyFiltersHistory"
                >
                  <md-option key="All" value="">{{ $t("wms.all") }}</md-option>
                  <md-option key="Add" value="Add">{{ $t("wms.add") }}</md-option>
                  <md-option key="Subtract" value="Subtract">{{ $t("wms.subtract") }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.historyType") }}</label>
                <md-select
                  v-model="historyFilters.historyTypeId"
                  name="filter.historyTypeId"
                  id="filter.historyTypeId"
                  @md-selected="debounceApplyFiltersHistory"
                >
                  <md-option :key="i.id" v-for="i in historyTypeOptions" :value="i.id">{{
                    getI18N("wms.statuses.historyTypes", i.name)
                  }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div style="">
              <md-field md-clearable>
                <label>{{ $t("wms.reason") }}</label>
                <md-input v-model="historyFilters.reason" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.changedBy") }}</label>
                <md-input v-model="historyFilters.changeBy" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
          </div>
          <div>
            <v-server-table
              :columns="tableColumns"
              :options="tableOptions"
              ref="table"
              class="table-inventories-history"
              id="table-inventories-history"
            >
              <template #warehouseName="{ row }">{{
                row.storageLocation && row.storageLocation.warehouseName
              }}</template>
              <template #dateTime="{ row }">
                {{ row.dateTime | formatDateTime }}
              </template>
              <template #location="{ row }">
                {{ row.storageLocation && row.storageLocation.fullName }}
              </template>
              <template #changedType="{ row }">
                <span v-if="row.changedType === 'Add'">
                  <span class="material-symbols-outlined">add</span>
                </span>
                <span v-else-if="row.changedType === 'Substract' || row.changedType === 'Subtract'">
                  <span class="material-symbols-outlined">remove</span>
                </span>
                <span v-else>
                  {{ row.changedType }}
                </span>
              </template>
              <template #changedBy="{ row }">
                {{ row.userWhoChangeName ? row.userWhoChangeName : "OCC" }}
              </template>
            </v-server-table>
          </div>
        </div>
      </template>
    </modal>

    <modal v-if="editModal" @close="hideEditModal" class-name="history-modal-container">
      <template slot="header">
        <h4 class="modal-title">
          {{ form.id > 0 ? $t("wms.editHistory") : $t("wms.addHistory") }}
        </h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <md-field>
          <label>{{ $t("wms.warehouses") }}</label>
          <md-select v-model="form.warehouseId" name="warehouseId" id="warehouseId">
            <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
          </md-select>
        </md-field>
        <p class="date-title">{{ $t("wms.dateTime") }}</p>
        <date-time-picker
          :startDate="form.dateTime"
          :singleDate="true"
          @onChange="(d) => (form.dateTime = d.startDate)"
          class="datetime-input"
        />
        <md-field>
          <label>{{ $t("wms.location") }}</label>
          <md-input v-model="form.storageLocation" type="text"></md-input>
        </md-field>
        <md-field>
          <label>{{ $t("wms.inStockOld") }}</label>
          <md-input v-model="form.inStockOld" type="text"> </md-input>
        </md-field>
        <md-field>
          <label>{{ $t("wms.inStockNew") }}</label>
          <md-input v-model="form.inStockNew" type="text"> </md-input>
        </md-field>
        <md-field>
          <label>{{ $t("wms.changedType") }}</label>
          <md-select v-model="form.changedType" name="changedType" id="changedType">
            <md-option value="Add">{{ $t("wms.add") }}</md-option>
            <md-option value="Subtract">{{ $t("wms.subtract") }}</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>{{ $t("wms.historyType") }}</label>
          <md-select v-model="form.historyTypeId" name="historyTypeId" id="historyTypeId">
            <md-option :key="i.id" v-for="i in historyTypeOptions" :value="i.id">{{
              getI18N("wms.statuses.historyTypes", i.name)
            }}</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>{{ $t("wms.reason") }}</label>
          <md-input v-model="form.reason" type="text"> </md-input>
        </md-field>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("wms.close") }}</md-button>
        <md-button class="md-success" @click="() => (form.id > 0 ? editHistory() : addHistory())">{{
          $t("wms.save")
        }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { sumBy, debounce } from "lodash";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import CSLoading from "../../components/Loading/CSLoading.vue";
import { Modal } from "@/components";
import axiosFactory from "../../services/axios.factory";

import { hasPermission, assignedWarehouse } from "../../mixins/auth";
import { normalizeStatusName } from "../../mixins/formats";

export default {
  components: {
    CSLoading,
    Modal,
    DateTimePicker,
  },
  data() {
    const userWarehouse = assignedWarehouse();
    return {
      editModal: false,
      isLoading: false,
      historyModal: false,
      name: null,
      sku: null,
      form: {
        id: 0,
      },
      historyFilters: {
        storageLocationId: null,
        changeType: "",
        changeBy: "",
        reason: "",
        historyTypeId: null,
        location: "",
      },
      filters: {
        keywords: "",
        warehouseId: userWarehouse ? userWarehouse.id : null,
      },
      pageSize: 100,
      table: null,
      tableColumns: [
        "warehouseName",
        "dateTime",
        "location",
        "inStockOld",
        "inStockNew",
        "quantity",
        "totalOld",
        "totalNew",
        "changedType",
        "reason",
        "changedBy",
        "historyTypeName",
      ],
      tableOptions: {
        headings: {
          warehouseName: () => this.$t("wms.warehouseName"),
          dateTime: () => this.$t("wms.dateTime"),
          location: () => this.$t("wms.location"),
          inStockOld: () => this.$t("wms.inStockOld"),
          inStockNew: () => this.$t("wms.inStockNew"),
          quantity: () => this.$t("wms.quantity"),
          totalOld: () => this.$t("wms.totalOld"),
          totalNew: () => this.$t("wms.totalNew"),
          changedType: () => this.$t("wms.changedType"),
          reason: () => this.$t("wms.reason"),
          changedBy: () => this.$t("wms.changedBy"),
          historyTypeName: () => this.$t("wms.historyType"),
        },
        perPage: 16,
        perPageValues: [16],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
      },
    };
  },
  computed: {
    tableData() {
      return this.$store.state.warehouses.skuStats || [];
    },
    warehouseOptions() {
      return this.hasPermission("LimitRead", "Inventories", "Name")
        ? this.$store.state.warehouses.warehouses || []
        : [];
    },
    historyTypeOptions() {
      return this.$store.state.warehouses.historyTypes || [];
    },
    isWarehouseAssigned() {
      return !!assignedWarehouse();
    },
  },
  methods: {
    hideEditModal() {
      this.editModal = false;
    },
    hideHistoryModal() {
      this.historyModal = false;
    },
    showEditHistoryModal(item) {
      Object.assign(this.form, item);
      this.form.dateTime = item.dateTime ? new Date(item.dateTime) : null;
      this.editModal = true;
    },
    showAddHistoryModal() {
      this.form = { id: 0, sku: this.sku };
      this.editModal = true;
    },
    showHistoryModal(sku) {
      this.sku = sku;
      this.currentPage = 1;
      this.historyModal = true;
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      const params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/warehouses/${this.sku}/histories`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      return {
        data: data.data.items,
        count: data.data.totalItems,
      };
    },
    rowClassCallback(row) {},
    addHistory() {
      this.isLoading = true;
      const inputs = Object.assign({}, this.form);
      axiosFactory()
        .post(`/warehouses/${this.form.sku}/histories`, inputs)
        .then((res) => {
          this.$store.dispatch("warehouses/getWarehouseHistoriesBySku", {
            sku: this.form.sku,
          });
        })
        .then(() => {
          this.hideEditModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editHistory() {
      this.isLoading = true;
      const inputs = Object.assign({}, this.form);
      axiosFactory()
        .put(`/warehouses/${this.form.sku}/histories/${this.form.id}`, inputs)
        .then((res) => {
          this.$store.dispatch("warehouses/getWarehouseHistoriesBySku", {
            sku: this.form.sku,
          });
        })
        .then(() => {
          this.hideEditModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calcTotal(item) {
      return sumBy(item.inventories, (i) => i.inventory);
    },
    showInventory(item, warehouseCode) {
      const inventory = item.inventories.find((i) => i.warehouseCode === warehouseCode);
      return inventory ? inventory.inventory : "-";
    },
    debounceApplyFilters: debounce(function () {
      this.applyFilters();
    }, 300),
    applyFilters() {
      this.isLoading = true;
      this.$store.dispatch("warehouses/getSkuStats", { ...this.filters }).then(() => {
        this.isLoading = false;
      });
    },
    dateFilterChanged(d, key) {
      this.historyFilters[key] = d.startDate;
      this.applyHistoryFilters({});
    },
    debounceApplyFiltersHistory: debounce(function () {
      this.applyHistoryFilters();
    }, 300),
    applyHistoryFilters() {
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.historyFilters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
    exportInventories() {
      this.isLoading = true;
      axiosFactory()
        .post(`/warehouses/export_inventories`)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("warehouses/getSkuStats", { ...this.filters }),
      this.$store.dispatch("warehouses/getWarehouses"),
      this.$store.dispatch("warehouses/getHistoryTypes"),
    ]).then(() => {
      this.isLoading = false;
    });
  },
};
</script>
<style scoped>
.details {
  height: calc(100vh - 200px);
}
.details .md-table-cell {
  text-align: left;
}
.mr-1 {
  margin-right: 15px;
}
.date-title {
  margin-bottom: 0;
  text-align: left;
  font-size: 0.85em;
  color: #888;
}
</style>
