var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[_c('div',{staticClass:"card-icon"},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("precision_manufacturing")])]),_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("suppliers.suppliers")))]),_c('md-button',{staticClass:"md-icon-button md-success add-button",on:{"click":function($event){_vm.name = null;
            _vm.createModal = true;}}},[_c('md-icon',[_vm._v("add")])],1)],1),_c('md-card-content',[_c('md-table',{attrs:{"table-header-color":"green"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
            var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"ID"}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('suppliers.name')}},[_vm._v(_vm._s(item.name))]),_c('md-table-cell',{attrs:{"md-label":_vm.$t('suppliers.actions')}},[_c('span',{on:{"click":function($event){return _vm.showEditModal(item)}}},[_c('md-icon',[_vm._v("edit")])],1)])],1)}}]),model:{value:(_vm.tableData),callback:function ($$v) {_vm.tableData=$$v},expression:"tableData"}})],1)],1)],1),(_vm.createModal)?_c('modal',{attrs:{"class-name":"shipping-plan-modal-container"},on:{"close":_vm.hideCreateModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("suppliers.createSupplier")))]),_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hideCreateModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("suppliers.name")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)})]}}],null,false,3537600405)})],1),_c('template',{slot:"footer"},[_c('md-button',{staticClass:"md-secondary mr-1",on:{"click":_vm.hideCreateModal}},[_vm._v(_vm._s(_vm.$t("suppliers.close")))]),_c('md-button',{staticClass:"md-success",on:{"click":_vm.createSupplier}},[_vm._v(_vm._s(_vm.$t("suppliers.close")))])],1)],2):_vm._e(),(_vm.editModal)?_c('modal',{attrs:{"class-name":"shipping-plan-modal-container"},on:{"close":_vm.hideEditModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("suppliers.editSupplier")))]),_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hideEditModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("suppliers.name")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)})]}}],null,false,3537600405)})],1),_c('template',{slot:"footer"},[_c('md-button',{staticClass:"md-secondary mr-1",on:{"click":_vm.hideEditModal}},[_vm._v(_vm._s(_vm.$t("suppliers.close")))]),_c('md-button',{staticClass:"md-success",on:{"click":_vm.editSupplier}},[_vm._v(_vm._s(_vm.$t("suppliers.save")))])],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }