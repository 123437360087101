<template>
  <v-client-table :data="tableData" :columns="tableColumns" :options="tableOptions">
    <template #menuPermission="{ row }">
      <input type="checkbox" :checked="hasMenuPermission(row.menu)" @click="handleSelectMenuPermission(row.menu)" />
    </template>
    <template #readPermission="{ row }">
      <div class="align-left">
        <p class="read column">
          <input
            type="checkbox"
            :disabled="hasPermission(row.menu, 'Write', '')"
            :checked="hasPermission(row.menu, 'Read', '')"
            @click="handleSelectPermission(row.menu, 'Read', '')"
          />
          Read
        </p>
        <p class="column" :key="col" v-for="col in getTableColumns(row.menu)">
          <input
            type="checkbox"
            :disabled="hasPermission(row.menu, 'Write', '') || hasPermission(row.menu, 'Read', '')"
            :checked="hasPermission(row.menu, 'LimitRead', col)"
            @click="handleSelectPermission(row.menu, 'LimitRead', col)"
          />
          {{ col }}
        </p>
      </div>
    </template>
    <template #writePermission="{ row }"
      ><p class="write column">
        <input
          type="checkbox"
          :checked="hasPermission(row.menu, 'Write', '')"
          @click="handleSelectPermission(row.menu, 'Write', '')"
        />
        Write
      </p></template
    >
  </v-client-table>
</template>

<script>
import { cloneDeep, remove } from "lodash";
import axiosFactory from "../../services/axios.factory";

export default {
  components: {},
  props: {
    userData: null,
  },
  data() {
    return {
      user: cloneDeep(this.userData),
      tableColumnPermissions: [],
      tableColumns: ["menu", "menuPermission", "readPermission", "writePermission"],
      tableData: [
        { menu: "ShippingPlans", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Products", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Orders", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Suppliers", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Warehouses", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "StorageLocations", menuPermission: true, readPermission: [], writePermission: true },
        { menu: "Inventories", menuPermission: true, readPermission: [], writePermission: true },
      ],
      tableOptions: {
        headings: {
          menu: () => this.$t("users.menu"),
          menuPermission: () => this.$t("users.menuPermission"),
          readPermission: () => this.$t("users.readPermission"),
          writePermission: () => this.$t("users.writePermission"),
        },
        perPage: 10,
        perPageValues: [10],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        pagination: {
          show: false,
        },
      },
    };
  },
  computed: {
    roleOptions() {
      return this.$store.state.users.roles || [];
    },
    tableNameOptions() {
      return ["ShippingPlans", "Products", "Orders", "Suppliers", "Warehouses", "StorageLocations", "Inventories"];
    },
    permissionOptions() {
      return ["LimitRead", "Read", "Write"];
    },
  },
  methods: {
    getTableColumns(menu) {
      if (this.tableColumnPermissions && this.tableColumnPermissions.hasOwnProperty(menu)) {
        return this.tableColumnPermissions[menu];
      }

      return [];
    },
    hasMenuPermission(menu) {
      return this.user && this.user.menuPermissions && this.user.menuPermissions.includes(menu);
    },
    handleSelectMenuPermission(menu) {
      if (this.user.menuPermissions.includes(menu)) {
        remove(this.user.menuPermissions, (p) => p === menu);
      } else {
        this.user.menuPermissions.push(menu);
      }

      this.$emit("userPerimissionsUpdated", {
        tableColumnPermissions: this.user.tableColumnPermissions,
        menuPermissions: this.user.menuPermissions,
      });
    },
    hasPermission(table, permission, col) {
      if (!this.user) return false;

      if (!this.user.tableColumnPermissions[table]) return false;

      if (!this.user.tableColumnPermissions[table][permission]) return false;

      if (permission === "Read" || permission === "Write") {
        return this.user.tableColumnPermissions[table][permission].includes("ALL");
      }

      return this.user.tableColumnPermissions[table][permission].includes(col);
    },
    handleSelectPermission(table, permission, col) {
      if (permission === "Read" || permission === "Write") {
        if (!this.user.tableColumnPermissions[table]) {
          this.user.tableColumnPermissions[table] = {};
          this.user.tableColumnPermissions[table][permission] = ["ALL"];
        } else if (!this.user.tableColumnPermissions[table][permission]) {
          this.user.tableColumnPermissions[table][permission] = ["ALL"];
        } else if (this.user.tableColumnPermissions[table][permission].includes("ALL")) {
          this.user.tableColumnPermissions[table][permission] = [];
        } else {
          this.user.tableColumnPermissions[table][permission] = ["ALL"];
        }

        this.user.tableColumnPermissions = cloneDeep(this.user.tableColumnPermissions);
      } else {
        if (!this.user.tableColumnPermissions[table]) {
          this.user.tableColumnPermissions[table] = {};
          this.user.tableColumnPermissions[table][permission] = [col];
        } else if (!this.user.tableColumnPermissions[table][permission]) {
          this.user.tableColumnPermissions[table][permission] = [col];
        } else if (this.user.tableColumnPermissions[table][permission].includes(col)) {
          remove(this.user.tableColumnPermissions[table][permission], (p) => p === col);
        } else {
          this.user.tableColumnPermissions[table][permission].push(col);
        }

        this.user.tableColumnPermissions = cloneDeep(this.user.tableColumnPermissions);
      }

      this.$emit("userPerimissionsUpdated", {
        tableColumnPermissions: this.user.tableColumnPermissions,
        menuPermissions: this.user.menuPermissions,
      });
    },
  },
  created() {
    Promise.all([this.$store.dispatch("users/getUsers"), this.$store.dispatch("users/getRoles")]).finally((_) => {
      this.isLoading = false;
    });
    axiosFactory()
      .get(`/users/table_columns`)
      .then((res) => {
        this.tableColumnPermissions = res.data.data;
      });
  },
};
</script>
<style scoped>
p.column {
  margin-bottom: 5px;
}
p.read,
p.write {
  margin-bottom: 20px;
}
.align-left {
  text-align: left;
}
</style>
