import axiosFactory from "./axios.factory";

class WarehouseService {
  async getWarehouses() {
    const result = await axiosFactory().get("/warehouses");
    return result.data.data;
  }

  async getLocationsByWarehouse({ warehouseId, location, storageLocationId }) {
    let loc = "";
    if (location) {
      loc = location;
    }
    let locId = null;
    if(storageLocationId){
      locId = storageLocationId;
    }
    const result = await axiosFactory().get(`/warehouses/storage_locations`, {params: {warehouseId, location, storageLocationId}});
    return result.data.data;
  }

  async getHistoryTypes() {
    const result = await axiosFactory().get("/warehouses/history_types");
    return result.data.data;
  }

  async getSkuStats({ keywords, warehouseId }) {
    const result = await axiosFactory().get("/warehouses/sku_stats", {
      params: { keywords, warehouseId },
    });
    return result.data.data;
  }

  async getWarehouseInventoriesBySku(sku) {
    const result = await axiosFactory().get(`/warehouses/${sku}/details`);
    return result.data.data;
  }

  async getWarehouseHistoriesBySku(sku) {
    const result = await axiosFactory().get(`/warehouses/${sku}/histories`);
    return result.data.data;
  }

  async createStorageLocation(opts) {
    const result = await axiosFactory().post(`/warehouses/storage_locations`, opts);
    return result.data.data;
  }
}

export default new WarehouseService();
