import WarehouseService from "../services/warehouse.service";

const initialState = {
  warehouses: [],
  locationsByWarehouse: [],
  storageLocations: [],
  historyTypes: [],
  skuStats: [],
  warehouseInventoriesBySku: [],
  warehouseHistoriesBySku: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    getWarehouses({ commit }) {
      return WarehouseService.getWarehouses().then(
        (result) => {
          commit("getWarehousesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getWarehousesFailure");
          return Promise.reject(error);
        }
      );
    },
    getStorageLocations({ commit }) {
      return WarehouseService.getLocationsByWarehouse({ warehouseId: 0, location: "" }).then(
        (result) => {
          commit("getStorageLocationsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getStorageLocationsFailure");
          return Promise.reject(error);
        }
      );
    },
    getLocationsByWarehouse({ commit }, opts) {
      return WarehouseService.getLocationsByWarehouse(opts).then(
        (result) => {
          commit("getLocationsByWarehouseSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getLocationsByWarehouseFailure");
          return Promise.reject(error);
        }
      );
    },
    getHistoryTypes({ commit }) {
      return WarehouseService.getHistoryTypes().then(
        (result) => {
          commit("getHistoryTypesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getHistoryTypesFailure");
          return Promise.reject(error);
        }
      );
    },
    getSkuStats({ commit }, opts) {
      if (!opts.warehouseId) {
        opts.warehouseId = undefined;
      }

      return WarehouseService.getSkuStats(opts).then(
        (result) => {
          commit("getSkuStatsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getSkuStatsFailure");
          return Promise.reject(error);
        }
      );
    },
    getWarehouseInventoriesBySku({ commit }, { sku }) {
      return WarehouseService.getWarehouseInventoriesBySku(sku).then(
        (result) => {
          commit("getWarehouseInventoriesBySkuSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getWarehouseInventoriesBySkuFailure");
          return Promise.reject(error);
        }
      );
    },
    getWarehouseHistoriesBySku({ commit }, { sku }) {
      return WarehouseService.getWarehouseHistoriesBySku(sku).then(
        (result) => {
          commit("getWarehouseHistoriesBySkuSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getWarehouseHistoriesBySkuFailure");
          return Promise.reject(error);
        }
      );
    },
    createStorageLocation({ commit }, opts) {
      return WarehouseService.createStorageLocation(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getWarehousesSuccess(state, result) {
      state.warehouses = result;
    },
    getWarehousesFailure(state) {
      state.warehouses = null;
    },
    getStorageLocationsSuccess(state, result) {
      state.storageLocations = result;
    },
    getStorageLocationsFailure(state) {
      state.storageLocations = null;
    },
    getLocationsByWarehouseSuccess(state, result) {
      state.locationsByWarehouse = result;
    },
    getLocationsByWarehouseFailure(state) {
      state.locationsByWarehouse = null;
    },
    getHistoryTypesSuccess(state, result) {
      state.historyTypes = result;
    },
    getHistoryTypesFailure(state) {
      state.historyTypes = null;
    },
    getSkuStatsSuccess(state, result) {
      state.skuStats = result;
    },
    getSkuStatsFailure(state) {
      state.skuStats = null;
    },
    getWarehouseInventoriesBySkuSuccess(state, result) {
      state.warehouseInventoriesBySku = result;
    },
    getWarehouseInventoriesBySkuFailure(state) {
      state.warehouseInventoriesBySku = null;
    },
    getWarehouseHistoriesBySkuSuccess(state, result) {
      state.warehouseHistoriesBySku = result;
    },
    getWarehouseHistoriesBySkuFailure(state) {
      state.warehouseHistoriesBySku = null;
    },
  },
};
