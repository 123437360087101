var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('CSLoading',{attrs:{"is-loading":_vm.isLoading}}),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[_c('div',{staticClass:"card-icon"},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("package_2")])]),_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("products.products")))]),_c('md-button',{staticClass:"md-icon-button md-success add-button",on:{"click":_vm.showCreateModal}},[_c('md-icon',[_vm._v("add")])],1)],1),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-25"},[_c('md-field',{attrs:{"md-clearable":""}},[_c('label',[_vm._v(_vm._s(_vm.$t("products.keywords")))]),_c('md-input',{on:{"input":_vm.debounceApplyFilters},model:{value:(_vm.filters.keywords),callback:function ($$v) {_vm.$set(_vm.filters, "keywords", $$v)},expression:"filters.keywords"}})],1)],1),_c('div',{staticClass:"md-layout md-layout-item md-size-25"},[_c('md-checkbox',{model:{value:(_vm.enableBulky),callback:function ($$v) {_vm.enableBulky=$$v},expression:"enableBulky"}},[_vm._v(_vm._s(_vm.$t("products.bulky")))]),_c('md-switch',{attrs:{"disabled":!_vm.enableBulky},model:{value:(_vm.filterBulky),callback:function ($$v) {_vm.filterBulky=$$v},expression:"filterBulky"}})],1)]),_c('v-server-table',{ref:"table",staticClass:"table-products",attrs:{"columns":_vm.tableColumns,"options":_vm.tableOptions,"id":"table-products"},scopedSlots:_vm._u([{key:"h__hs",fn:function(){return [_c('div',{staticClass:"head-hs"},[_vm._v(_vm._s(_vm.$t("products.hsUK"))+", "+_vm._s(_vm.$t("products.hsEU"))+", "+_vm._s(_vm.$t("products.hsCH")))])]},proxy:true},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('span',{on:{"click":function($event){return _vm.showEditModal(row)}}},[_c('md-icon',[_vm._v("edit")])],1)]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-name"},[_vm._v(" "+_vm._s(row.name && row.name.substring(0, 50))+" ")])]}},{key:"shippingSizeLength",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-shipping-size-length"},[_vm._v(_vm._s(_vm._f("formatNumberGerman")(row.shippingSizeLength))+" cm")])]}},{key:"shippingSizeWidth",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-shipping-size-width"},[_vm._v(_vm._s(_vm._f("formatNumberGerman")(row.shippingSizeWidth))+" cm")])]}},{key:"shippingSizeHeight",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-shipping-size-height"},[_vm._v(_vm._s(_vm._f("formatNumberGerman")(row.shippingSizeHeight))+" cm")])]}},{key:"shippingSizeWeight",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-shipping-size-weight"},[_vm._v(_vm._s(_vm._f("formatNumberGerman")(row.shippingSizeWeight))+" kg")])]}},{key:"hs",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-hs"},[_vm._v(_vm._s(row.hsUK || 0)+", "+_vm._s(row.hsEU || 0)+", "+_vm._s(row.hsCH || 0))])]}},{key:"bulky",fn:function(ref){
var row = ref.row;
return [(row.bulky === 'true')?_c('span',{staticClass:"text-success material-symbols-outlined"},[_vm._v("done")]):(row.bulky === 'false')?_c('span',{staticClass:"text-danger material-symbols-outlined"},[_vm._v("close")]):_vm._e()]}},{key:"supplier",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-supplier"},[_vm._v(" "+_vm._s(row.supplier && row.supplier.name)+" ")])]}}])})],1)],1)],1),(_vm.createModal)?_c('modal',{attrs:{"class-name":"shipping-plan-modal-container"},on:{"close":_vm.hideCreateModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("products.createProduct")))]),_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hideCreateModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"createForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"sku","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.sku")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.name")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeLength")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeLength),callback:function ($$v) {_vm.shippingSizeLength=$$v},expression:"shippingSizeLength"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("cm")])],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeWidth")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeWidth),callback:function ($$v) {_vm.shippingSizeWidth=$$v},expression:"shippingSizeWidth"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("cm")])],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeHeight")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeHeight),callback:function ($$v) {_vm.shippingSizeHeight=$$v},expression:"shippingSizeHeight"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("cm")])],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeWeight")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeWeight),callback:function ($$v) {_vm.shippingSizeWeight=$$v},expression:"shippingSizeWeight"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("kg")])],1),_c('div',{staticStyle:{"width":"30%"}},[_c('md-switch',{staticClass:"md-primary",attrs:{"value":"true"},model:{value:(_vm.bulky),callback:function ($$v) {_vm.bulky=$$v},expression:"bulky"}},[_vm._v(_vm._s(_vm.$t("products.bulky")))])],1),_c('ValidationProvider',{attrs:{"name":"hsUK"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsUK")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsUK),callback:function ($$v) {_vm.hsUK=$$v},expression:"hsUK"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"hsEU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsEU")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsEU),callback:function ($$v) {_vm.hsEU=$$v},expression:"hsEU"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"hsCH"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsCH")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsCH),callback:function ($$v) {_vm.hsCH=$$v},expression:"hsCH"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"outboundCarrier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.outboundCarrier")))]),_c('md-select',{attrs:{"name":"outboundCarrier","id":"outboundCarrier"},model:{value:(_vm.outboundCarrier),callback:function ($$v) {_vm.outboundCarrier=$$v},expression:"outboundCarrier"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"carrierUK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.carrierUK")))]),_c('md-select',{attrs:{"name":"carrierUK","id":"carrierUK"},model:{value:(_vm.carrierUK),callback:function ($$v) {_vm.carrierUK=$$v},expression:"carrierUK"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"carrierEU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.carrierEU")))]),_c('md-select',{attrs:{"name":"carrierEU","id":"carrierEU"},model:{value:(_vm.carrierEU),callback:function ($$v) {_vm.carrierEU=$$v},expression:"carrierEU"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"supplierId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wrap-v-select",staticStyle:{"text-align":"left","margin-top":"15px"}},[_c('v-select',{attrs:{"label":"name","options":_vm.supplierNameOptions,"placeholder":_vm.$t('products.supplier')},model:{value:(_vm.supplierId),callback:function ($$v) {_vm.supplierId=$$v},expression:"supplierId"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)})]}}],null,false,2806669304)})],1),_c('template',{slot:"footer"},[_c('md-button',{staticClass:"md-secondary mr-1",on:{"click":_vm.hideCreateModal}},[_vm._v(_vm._s(_vm.$t("products.close")))]),_c('md-button',{staticClass:"md-success",on:{"click":_vm.createProduct}},[_vm._v(_vm._s(_vm.$t("products.save")))])],1)],2):_vm._e(),(_vm.editModal)?_c('modal',{attrs:{"class-name":"shipping-plan-modal-container"},on:{"close":_vm.hideEditModal}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Edit Product")]),_c('md-button',{staticClass:"md-simple md-just-icon md-round modal-default-button",on:{"click":_vm.hideEditModal}},[_c('md-icon',[_vm._v("clear")])],1)],1),_c('template',{slot:"body"},[_c('ValidationObserver',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"sku","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.sku")))]),_c('md-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.name")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeLength")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeLength),callback:function ($$v) {_vm.shippingSizeLength=$$v},expression:"shippingSizeLength"}})],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeWidth")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeWidth),callback:function ($$v) {_vm.shippingSizeWidth=$$v},expression:"shippingSizeWidth"}})],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeHeight")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeHeight),callback:function ($$v) {_vm.shippingSizeHeight=$$v},expression:"shippingSizeHeight"}})],1),_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.shippingSizeWeight")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.shippingSizeWeight),callback:function ($$v) {_vm.shippingSizeWeight=$$v},expression:"shippingSizeWeight"}})],1),_c('div',{staticStyle:{"width":"30%"}},[_c('md-switch',{staticClass:"md-primary",attrs:{"value":"true"},model:{value:(_vm.bulky),callback:function ($$v) {_vm.bulky=$$v},expression:"bulky"}},[_vm._v(_vm._s(_vm.$t("products.bulky")))])],1),_c('ValidationProvider',{attrs:{"name":"hsUK","rules":"max_value:9999999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsUK")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsUK),callback:function ($$v) {_vm.hsUK=$$v},expression:"hsUK"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"hsEU","rules":"max_value:9999999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsEU")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsEU),callback:function ($$v) {_vm.hsEU=$$v},expression:"hsEU"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"hsCH","rules":"max_value:9999999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("products.hsCH")))]),_c('md-input',{attrs:{"type":"number"},model:{value:(_vm.hsCH),callback:function ($$v) {_vm.hsCH=$$v},expression:"hsCH"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"outboundCarrier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.outboundCarrier")))]),_c('md-select',{attrs:{"name":"outboundCarrier","id":"outboundCarrier"},model:{value:(_vm.outboundCarrier),callback:function ($$v) {_vm.outboundCarrier=$$v},expression:"outboundCarrier"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"carrierUK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.carrierUK")))]),_c('md-select',{attrs:{"name":"carrierUK","id":"carrierUK"},model:{value:(_vm.carrierUK),callback:function ($$v) {_vm.carrierUK=$$v},expression:"carrierUK"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"carrierEU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v(_vm._s(_vm.$t("products.carrierEU")))]),_c('md-select',{attrs:{"name":"carrierEU","id":"carrierEU"},model:{value:(_vm.carrierEU),callback:function ($$v) {_vm.carrierEU=$$v},expression:"carrierEU"}},_vm._l((_vm.carrierOptions),function(i){return _c('md-option',{key:i.id,attrs:{"value":i.id}},[_vm._v(_vm._s(i.name))])}),1),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"supplierId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"wrap-v-select",staticStyle:{"text-align":"left","margin-top":"15px"}},[_c('v-select',{attrs:{"label":"name","options":_vm.supplierNameOptions,"placeholder":_vm.$t('products.supplier')},model:{value:(_vm.supplierId),callback:function ($$v) {_vm.supplierId=$$v},expression:"supplierId"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)})]}}],null,false,88940653)})],1),_c('template',{slot:"footer"},[_c('md-button',{staticClass:"md-secondary mr-1",on:{"click":_vm.hideEditModal}},[_vm._v(_vm._s(_vm.$t("products.close")))]),_c('md-button',{staticClass:"md-success",on:{"click":_vm.editProduct}},[_vm._v(_vm._s(_vm.$t("products.save")))])],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }